@import "../mixins/media";

/*
フッタ


Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-footerTitle {
  padding: 40px 0;
}

footer {
  padding: 1.5em 0 0;
  text-align: center;
  width: 100%;

  a {
    display: block;
  }

  .ec-footerRole {
    border-top: none;
  }

  .ec-footerNavi .ec-footerNavi__link a {
    border-bottom: 1px solid #ccc;
  }

  @media only screen and (min-width: 768px) {
    .ec-footerNavi .ec-footerNavi__link a {
      text-decoration: none;
      border-bottom: none;
    }
  }

  .shoppingGuide {
    p {
      margin-bottom: 0;
      padding: 0.5em 0 0.25em;
      text-align: center;
      font-size: 1.5em;
      line-height: 1;
      border-bottom: 3px solid #666;
    }

    ul {
      margin-bottom: 0;
    }

    li {
      position: relative;
      float: left;
      width: 50%;
      height: 50%;
      padding: 50% 0 0;
      text-align: left !important;
      box-sizing: border-box;
      border-left: #ccc solid 1px;
      border-bottom: #ccc solid 1px;
    }

    dl {
      position: absolute;
      left: 0;
      top: 5%;
      width: 100%;
      height: 90%;
      padding: 0 8%;
      color: #555;

      dt {
        text-align: center;
        margin-bottom: 0.5em;
        padding: 40% 0 0;
        font-weight: bold;

        &::before {
          @include font_awesome;
          @include icoCenter;

          margin: 2% 0 0 auto;
          color: #ffa64d;
          content: "\ea52";
          font-size: 400%;
        }

        &.pay::before {
          content: "\f157";
        }

        &.deli::before {
          content: "\f0d1";
        }

        &.point::before {
          content: "\f304";
        }

        &.change::before {
          content: "\f362";
        }

        &.point {
          color: #555;
        }
      }

      dd {
        font-size: 83.33333%;

        &::after {
          @include font_awesome;
          @include icoCenter;

          right: 5%;
          margin: auto auto 0 0;
          padding: 0;
          color: #ccc;
          font-size: 150%;
          height: 1.5em;
          content: "\f138";
          text-align: right;
        }
      }
    }
  }

  .lawNav {
    @include cf;

    li {
      position: relative;
      text-align: left !important;
      border-bottom: 1px #ccc solid;

      a {
        height: 1.5em;
        padding: 1em;
        color: #666;
        font-size: 125%;
      }

      &::after {
        @include font_awesome;
        @include icoCenter;

        width: 1em;
        height: 1em;
        margin: auto 0 auto auto;
        color: #ddd;
        content: "\f105";
        font-size: 2em;
      }
    }
  }

  .siteseal {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    border-bottom: 1px #ccc solid;

    dl {
      width: 90%;
      margin: auto;
      padding: 20px 0;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;

      dt {
        width: 30%;

        img {
          width: 100%;
        }
      }

      dd {
        width: 66%;
        text-align: left;
      }
    }
  }

  .inquiryMedia {
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;

    a {
      display: inline-block;
    }
  }

  @media screen and (min-width: 768px) {
    .shoppingGuide {
      p {
        margin-bottom: 0.5em;

        &::after {
          content: "Shopping guide";
          color: #ffa64d;
          font-size: 75%;
          margin-left: 1em;
          font-family: Times, serif;
          text-indent: 0.25em;
        }
      }

      ul {
        margin-bottom: 1em;

        li {
          width: 25%;
          height: 25%;
          padding: 25% 0 0;
          border: none;
        }
      }

      dl {
        dt {
          &::before {
            font-size: 300%;
          }
        }

        dd {
          font-size: 87.5%;
        }
      }

      .siteseal dl {
        width: 620px;

        dt {
          width: 30%;
          max-width: 140px;
        }

        dd {
          width: 460px;
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    margin: 0 auto;

    .shoppingGuide {
      ul {
        border-bottom: none;
        width: 1000px;
        margin: 0 auto;

        li {
          width: 25%;
          height: 18.75%;
          padding: 18.75% 0 0;
          border: none;
        }
      }

      &.clearfix {
        padding: 1em 0;
        box-sizing: border-box;
        border-radius: 7px;
        width: 1000px;
        margin: 0 auto;
      }
    }

    .lawNav {
      width: 600px;
      margin: 0 auto;

      ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: stretch;
        align-items: stretch;

        li {
          border: none;
          padding: 0;

          &::after {
            display: none;
          }

          a {
            font-size: 75%;
          }
        }
      }
    }

    .siteseal {
      width: 1000px;
      border-bottom: none;
    }

    #copyright {
      font-size: 60%;

      a {
        display: inline;
        color: #333;
      }
    }
  }
}
