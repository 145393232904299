@import "../mixins/media";

/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.header {
  @media screen and (min-width: 768px) {
    margin-bottom: 1em;
  }
}

.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
  background: #360;
}

.ec-headerNav {
  .ec-headerNav__itemLink {
    @media screen and (min-width: 768px) {
      font-size: 12px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
  }
}

.ec-headerNaviRole .ec-headerNaviRole__right {
  @media screen and (min-width: 768px) {}
}

.ec-headerNaviRole {

  .header-contact-pc {
    display: none;
  }

  @media screen and (min-width: 768px) {
    position: relative;
    margin-bottom: 20px;
    padding: 5px 20px 0;
    background-color: #fffaea;

    .ec-headerNaviRole__left {
      display: none;
    }

    .ec-headerNaviRole__right {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .header-contact-pc {
      display: block;
      position: absolute;
      right: 30px;
      top: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .ec-headerNaviRole__left {
      display: block;
      width: 45%;
    }

    .ec-headerNaviRole__search {
      margin-top: 0;
    }

    .ec-headerNaviRole__right {
      width: 55%;
      box-sizing: border-box;
      padding-right: 220px;
    }
  }
}


.ec-headerRole {
  padding-top: 0;

  .ec-headerTitle {
    background: #360;

    .ec-headerTitle__title {
      h1 {
        margin-bottom: .5em;
        padding: .5em 0;

        a {
          margin-bottom: 0;

        }

        img {
          width: 50%;

          @media screen and (min-width: 768px) {
            width: 100%;
          }
        }

        @media screen and (min-width: 768px) {
          width: 18%;
          max-width: 200px;
          margin: 0;
          padding: 0;
          text-align: left;
        }
      }

      .header-delivery {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
          width: 550px;
          line-height: 1.5;
          text-align: center;

          .ec-headerNaviRole__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
              @media screen and (min-width: 768px) {
                margin-bottom: 0;
                font-weight: normal;
                font-size: 20px;
              }
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1090px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media screen and (min-width: 768px) {
      background: none;
    }
  }
}

.header {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  font-size: 0;
  line-height: 0;
  background: #006834;

  a {
    display: block;
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  .header-nav-cart,
  .header-delivery,
  .header-tel,
  .header-nav-main {
    display: none;
  }

  // nav
  @media screen and (min-width: 768px) {
    position: relative;
    background: #fff;
    font-size: 100%;
    font-weight: bold;
    line-height: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    align-items: stretch;

    .header-nav-local {
      display: block;
      width: 66%;
      padding-top: 0.5em;

      ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-items: stretch;
        align-items: stretch;
        padding: 0;
      }

      li {
        width: 18%;
        padding-right: 2%;
        font-size: 75%;
        text-align: left;

        &:nth-of-type(3) {
          width: 23%;
        }

        a {
          color: #000;
          width: 100%;
          height: 100%;
          padding: 1em 0 1em 2%;
          line-height: 1;
        }

        @media screen and (min-width: 1024px) {
          font-size: 100%;
        }
      }
    }

    .header-tel {
      display: block;
      width: 33%;
      padding: 1em 1% 0.5em 0;
      text-align: left;
    }

    .header-nav-main {
      display: block;
      width: 100%;
      margin-bottom: 0;

      ul {
        overflow: hidden;
        padding: 0;
        margin: 0;

        li {
          width: 20%;
          float: left;
          border-top: #000 3px solid;
          border-bottom: #000 3px solid;
          border-right: #999 2px dotted;
          box-sizing: border-box;

          a {

            &:active,
            &:hover,
            &:link,
            &:visited {
              color: #000;
              padding: 1em 0;
              text-align: center;
            }

            &:hover {
              color: #ffffb4;
              background-color: #000;
            }

            &::before {
              @include font_awesome;

              padding: 0 4px 0 0;
              font-size: 90%;
            }
          }

          &:nth-child(5) {
            background-color: $color-warm;

            a {
              color: #fff;
            }
          }

          &:nth-child(1) a::before {
            content: "\f015";
          }

          &:nth-child(2) a::before {
            content: "\f022";
          }

          &:nth-child(3) a::before {
            content: "\f290";
          }

          &:nth-child(4) a::before {
            content: "\f086";
          }

          &:nth-child(5) a::before {
            content: "\f05a";
          }

          &:nth-child(6) a::before {
            content: "\f0a4";
          }

          &.on_menu {
            a {
              color: #ffffb4;
              background-color: #000;
            }
          }
        }

        // li
      }

      // ul
    }
  }

  @media screen and (min-width: 1024px) {
    margin: 0 auto 1em;
    z-index: 1;

    .header-nav-main {
      border: #000 3px solid;
      border-radius: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #ff9400;
      font-size: 115%;

      ul {
        li {
          width: 20%;
          float: left;
          background: #fff;
          border-top: none;
          border-bottom: none;

          &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          a {
            &:hover {
              color: #ffffb4;
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

.ec-footerRole {
  margin-top: 30px;
  background: #f4f4e9;

  .ec-footerNavi {
    color: #333;
  }

  .ec-footerTitle {
    color: #333;
  }
}

.ec-drawerRole {

  .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
    background: #360;
  }

  .ec-headerLinkArea {
    background: #f4f4e9;
    padding-bottom: 33%;

    .ec-headerLink__item {
      border-bottom: 1px solid #999;
      color: #333;
    }
  }

  .ec-headerCategoryArea .ec-headerCategoryArea__heading {
    color: #fff;
    background: #360;
    // background: #F8F8F8;
  }

  .ec-headerTopArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8;
  }

  .ec-headerTop {
    padding: 16px 8px 26px;
    background: #EBEBEB;
    color: #636378;
  }

  .ec-itemNav__main {
    li {
      a {

        &:active,
        &:hover,
        &:link,
        &:visited {
          color: #000;
        }

        &::before {
          @include font_awesome;

          padding: 0 4px 0 0;
          font-size: 90%;
        }
      }

      &:nth-child(1) a::before {
        content: "\f015";
      }

      &:nth-child(2) a::before {
        content: "\f022";
      }

      &:nth-child(3) a::before {
        content: "\f290";
      }

      &:nth-child(4) a::before {
        content: "\f086";
      }

      &:nth-child(5) a::before {
        content: "\f05a";
      }

      &:nth-child(6) a::before {
        content: "\f0a4";
      }
    }
  }
}

.fa-shopping-cart:before {
  color: #ff791c;
}

.fa-user:before {
  color: #555;
}

.fa-heart:before {
  color: deeppink;
}

.fa-lock:before {
  color: goldenrod;
}

.fa-home:before {
  color: #360;
}
