@import "../mixins/media";

/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1


*/
ol,
ul,
li {
  list-style: none;
  padding-left: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  border: none;
}

.clearfix {
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

em,
strong {
  font-style: normal;
}

.only-sp {
  display: block !important;

  @media screen and (min-width : 768px) {
    display: none !important;
  }
}

.only-pc {
  display: none !important;

  @media screen and (min-width : 768px) {
    display: block !important;
  }
}

// =====
// パンくず
// =====
.breadcrumb {
  margin: 0 0 1em;
  font-size: 90%;
  text-align: left;

  ul,
  ol {
    li {
      display: inline;
      margin: 0 2px 0 0;

      &::after {
        content: " › ";
      }

      &.last::after {
        content: "";
      }
    }
  }
}

.ttl-cmn-02 {
  background-color: #66a366;
  margin-bottom: .5em;
  padding-bottom: .25em;
  font-size: 200%;
  font-weight: bold;
  color: #333;
  text-align: left;
  background: #fff;
  border-bottom: 3px solid #006834;
  line-height: 1;

  &::before {
    font-family: "Font Awesome 5 Pro";
    speak: none;
    font-style: normal;
    font-weight: 700;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 10px 0 0;
    color: #1a6600;
    content: "";
    font-size: 100%;
  }
}

.clearfix {
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

.img_inq {
  width: 75%;

  @media screen and (min-width: 768px) {
    width: 33%;
  }
}

.ec-ContentsRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;

  .img_inq {
    @extend .img_inq;
  }
}


/* @import url("/css/layout.css"); */

.w50 {
  width: 50% !important;
}

.w70 {
  width: 70% !important;
}

.w100 {
  width: 100% !important;
}

br.clear {
  clear: both;
  line-height: 0px;
  height: 0px;
  font-size: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
  content: '';
}

.cf,
.clearfix {
  overflow: hidden;
  zoom: 1;
}

.clear {
  clear: both;
}

.f_right {
  float: right;
}

.f_left {
  float: left;
}

.t_right {
  text-align: right !important;
}

.t_left {
  text-align: left !important;
}

.t_center {
  text-align: center !important;
}

/*inline均等*/
.t_just {
  text-justify: inter-ideograph;
  text-align: justify;
}

.font-normal {
  font-family: Avenir, '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

.mt0 {
  margin-top: 0px !important;
}

.mt5 {
  margin-top: 3px !important;
}

.mt10 {
  margin-top: 5px !important;
}

.mt15 {
  margin-top: 8px !important;
}

.mt20 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 15px !important;
}

.mt40 {
  margin-top: 20px !important;
}

.mt50 {
  margin-top: 25px !important;
}

.mt60 {
  margin-top: 30px !important;
}

.mt70 {
  margin-top: 35px !important;
}

.mt80 {
  margin-top: 40px !important;
}

.mt90 {
  margin-top: 45px !important;
}

.mt100 {
  margin-top: 50px !important;
}

.mt-10 {
  margin-top: -5px !important;
}

.mt-20 {
  margin-top: -10px !important;
}

.mt-30 {
  margin-top: -15px !important;
}

.mt-40 {
  margin-top: -20px !important;
}

.mt-50 {
  margin-top: -25px !important;
}

.mlauto {
  margin-left: auto;
  margin-right: auto;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 3px !important;
}

.ml10 {
  margin-left: 5px !important;
}

.ml15 {
  margin-left: 8px !important;
}

.ml20 {
  margin-left: 10px !important;
}

.ml30 {
  margin-left: 15px !important;
}

.ml40 {
  margin-left: 20px !important;
}

.ml50 {
  margin-left: 25px !important;
}

.ml55 {
  margin-left: 28px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 3px !important;
}

.mb10 {
  margin-bottom: 5px !important;
}

.mb15 {
  margin-bottom: 8px !important;
}

.mb20 {
  margin-bottom: 10px !important;
}

.mb30 {
  margin-bottom: 15px !important;
}

.mb40 {
  margin-bottom: 20px !important;
}

.mb50 {
  margin-bottom: 25px !important;
}

.mb60 {
  margin-bottom: 30px !important;
}

.mb70 {
  margin-bottom: 35px !important;
}

.mb80 {
  margin-bottom: 40px !important;
}

.mb90 {
  margin-bottom: 45px !important;
}

.mb100 {
  margin-bottom: 50px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 3px !important;
}

.mr10 {
  margin-right: 5px !important;
}

.mr15 {
  margin-right: 8px !important;
}

.mr20 {
  margin-right: 10px !important;
}

.mr30 {
  margin-right: 15px !important;
}

.mr40 {
  margin-right: 20px !important;
}

.mr50 {
  margin-right: 25px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pt5 {
  padding-top: 3px !important;
}

.pt10 {
  padding-top: 5px !important;
}

.pt15 {
  padding-top: 8px !important;
}

.pt20 {
  padding-top: 10px !important;
}

.pt30 {
  padding-top: 15px !important;
}

.pt40 {
  padding-top: 20px !important;
}

.pt50 {
  padding-top: 25px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl5 {
  padding-left: 3px !important;
}

.pl10 {
  padding-left: 5px !important;
}

.pl15 {
  padding-left: 8px !important;
}

.pl20 {
  padding-left: 10px !important;
}

.pl30 {
  padding-left: 15px !important;
}

.pl40 {
  padding-left: 20px !important;
}

.pl50 {
  padding-left: 25px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb5 {
  padding-bottom: 3px !important;
}

.pb10 {
  padding-bottom: 5px !important;
}

.pb15 {
  padding-bottom: 8px !important;
}

.pb20 {
  padding-bottom: 10px !important;
}

.pb30 {
  padding-bottom: 15px !important;
}

.pb40 {
  padding-bottom: 20px !important;
}

.pb50 {
  padding-bottom: 25px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr5 {
  padding-right: 3px !important;
}

.pr10 {
  padding-right: 5px !important;
}

.pr15 {
  padding-right: 8px !important;
}

.pr20 {
  padding-right: 10px !important;
}

.pr30 {
  padding-right: 15px !important;
}

.pr40 {
  padding-right: 20px !important;
}

.pr50 {
  padding-right: 25px !important;
}

@media screen and (min-width : 768px) {
  .mt0 {
    margin-top: 0px !important;
  }

  .mt5 {
    margin-top: 5px !important;
  }

  .mt10 {
    margin-top: 10px !important;
  }

  .mt15 {
    margin-top: 15px !important;
  }

  .mt20 {
    margin-top: 20px !important;
  }

  .mt30 {
    margin-top: 30px !important;
  }

  .mt40 {
    margin-top: 40px !important;
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .mt60 {
    margin-top: 60px !important;
  }

  .mt70 {
    margin-top: 70px !important;
  }

  .mt80 {
    margin-top: 80px !important;
  }

  .mt90 {
    margin-top: 90px !important;
  }

  .mt100 {
    margin-top: 100px !important;
  }

  .mt-10 {
    margin-top: -10px !important;
  }

  .mt-20 {
    margin-top: -20px !important;
  }

  .mt-30 {
    margin-top: -30px !important;
  }

  .mt-40 {
    margin-top: -40px !important;
  }

  .mt-50 {
    margin-top: -50px !important;
  }

  .mlauto {
    margin-left: auto;
    margin-right: auto;
  }

  .ml0 {
    margin-left: 0px !important;
  }

  .ml5 {
    margin-left: 5px !important;
  }

  .ml10 {
    margin-left: 10px !important;
  }

  .ml15 {
    margin-left: 15px !important;
  }

  .ml20 {
    margin-left: 20px !important;
  }

  .ml30 {
    margin-left: 30px !important;
  }

  .ml40 {
    margin-left: 40px !important;
  }

  .ml50 {
    margin-left: 50px !important;
  }

  .ml55 {
    margin-left: 55px !important;
  }

  .mb0 {
    margin-bottom: 0px !important;
  }

  .mb5 {
    margin-bottom: 5px !important;
  }

  .mb10 {
    margin-bottom: 10px !important;
  }

  .mb15 {
    margin-bottom: 15px !important;
  }

  .mb20 {
    margin-bottom: 20px !important;
  }

  .mb30 {
    margin-bottom: 30px !important;
  }

  .mb40 {
    margin-bottom: 40px !important;
  }

  .mb50 {
    margin-bottom: 50px !important;
  }

  .mb60 {
    margin-bottom: 60px !important;
  }

  .mb70 {
    margin-bottom: 70px !important;
  }

  .mb80 {
    margin-bottom: 80px !important;
  }

  .mb90 {
    margin-bottom: 90px !important;
  }

  .mb100 {
    margin-bottom: 100px !important;
  }

  .mr0 {
    margin-right: 0px !important;
  }

  .mr5 {
    margin-right: 5px !important;
  }

  .mr10 {
    margin-right: 10px !important;
  }

  .mr15 {
    margin-right: 15px !important;
  }

  .mr20 {
    margin-right: 20px !important;
  }

  .mr30 {
    margin-right: 30px !important;
  }

  .mr40 {
    margin-right: 40px !important;
  }

  .mr50 {
    margin-right: 50px !important;
  }

  .pt0 {
    padding-top: 0px !important;
  }

  .pt5 {
    padding-top: 5px !important;
  }

  .pt10 {
    padding-top: 10px !important;
  }

  .pt15 {
    padding-top: 15px !important;
  }

  .pt20 {
    padding-top: 20px !important;
  }

  .pt30 {
    padding-top: 30px !important;
  }

  .pt40 {
    padding-top: 40px !important;
  }

  .pt50 {
    padding-top: 50px !important;
  }

  .pl0 {
    padding-left: 0px !important;
  }

  .pl5 {
    padding-left: 5px !important;
  }

  .pl10 {
    padding-left: 10px !important;
  }

  .pl15 {
    padding-left: 15px !important;
  }

  .pl20 {
    padding-left: 20px !important;
  }

  .pl30 {
    padding-left: 30px !important;
  }

  .pl40 {
    padding-left: 40px !important;
  }

  .pl50 {
    padding-left: 50px !important;
  }

  .pb0 {
    padding-bottom: 0px !important;
  }

  .pb5 {
    padding-bottom: 5px !important;
  }

  .pb10 {
    padding-bottom: 10px !important;
  }

  .pb15 {
    padding-bottom: 15px !important;
  }

  .pb20 {
    padding-bottom: 20px !important;
  }

  .pb30 {
    padding-bottom: 30px !important;
  }

  .pb40 {
    padding-bottom: 40px !important;
  }

  .pb50 {
    padding-bottom: 50px !important;
  }

  .pr0 {
    padding-right: 0px !important;
  }

  .pr5 {
    padding-right: 5px !important;
  }

  .pr10 {
    padding-right: 10px !important;
  }

  .pr15 {
    padding-right: 15px !important;
  }

  .pr20 {
    padding-right: 20px !important;
  }

  .pr30 {
    padding-right: 30px !important;
  }

  .pr40 {
    padding-right: 40px !important;
  }

  .pr50 {
    padding-right: 50px !important;
  }
}


.ec-productRole__description__detail {
  font-size: 75%;
}

/* ***************************************************************** */

/* お客様の声 */

/* ***************************************************************** */

// 共通レビュー 非表示
#product_review_area {
  display: none;
}

.block_voice {
  margin: 0.5em 0 3em;
  width: 100%;
  padding: 2em 3% 1em;
  text-align: left;
  border: 2px solid #e1e1e1;
  border-radius: 15px;
  box-sizing: border-box;

  .ratingValue {
    font-size: 200%;
    color: #f90;
    font-weight: bold;
  }

  .reviewTitle {
    font-size: 83.33333%;
    text-align: center;
  }

  .voice {
    ul {
      @include cf;

      width: 94%;
      margin: 0;
      padding: 0 3%;
      background-color: #ffed99;

      li {
        float: left;
        border-right: 0;

        // お名前
        &:nth-child(1) {
          width: 100%;
        }

        // 評価星
        &:nth-child(2) {
          width: 100%;
        }

        // 日付
        &:nth-child(3) {
          width: 100%;
          text-align: right;
        }

        &.star1 {
          background: #ffed99 url("/html/template/million01/assets/img/common/star1.png") right center no-repeat;
        }

        &.star2 {
          background: #ffed99 url("/html/template/million01/assets/img/common/star2.png") right center no-repeat;
        }

        &.star3 {
          background: #ffed99 url("/html/template/million01/assets/img/common/star3.png") right center no-repeat;
        }

        &.star4 {
          background: #ffed99 url("/html/template/million01/assets/img/common/star4.png") right center no-repeat;
        }

        &.star5 {
          background: #ffed99 url("/html/template/million01/assets/img/common/star5.png") right center no-repeat;
        }
      }
    }

    .message {
      width: 94%;
      margin: 0 auto;
      padding: 0.5em 3% 1em;
      background-color: #ffffe0;
    }
  }

  .reviewArea {
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      height: 3em;
      line-height: 1;
      border-radius: 15px;

      li {
        float: left;
        width: 50%;
        height: 2em;
        padding: 0;
        list-style: none;
        border-radius: 5px;

        &.reviewV {
          background: #2653ae;
          background: -webkit-gradient(linear, left top, left bottom, from(#2653ae), to(#1a3977));
          background: -webkit-linear-gradient(top, #2653ae, #1a3977);
          background: linear-gradient(to bottom, #2653ae, #1a3977);
          background-size: 100% auto;
          padding: 0;
          width: 50%;
          height: 3em;
          text-align: right;
          position: relative;
          margin-right: 0;

          &::before {
            @include font_awesome;

            content: "\f075";
            position: absolute;
            left: 0.5em;
            top: 0.3em;
            color: $color-white;
            font-size: 200%;
          }

          a {
            display: block;
            width: 100%;
            height: 1em;
            font-weight: bold;
            text-align: left;
            padding: 1em 5%;
            color: $color-white;
            text-align: center;

            &::before {
              content: "レビューを見る ";
            }
          }
        }

        &.reviewW {
          background: #6dd900;
          background: -webkit-gradient(linear, left top, left bottom, from(#6dd900), to(#468c00));
          background: -webkit-linear-gradient(top, #6dd900, #468c00);
          background: linear-gradient(to bottom, #6dd900, #468c00);
          background-size: 100% auto;
          padding: 0;
          width: 49%;
          height: 3em;
          text-align: right;
          position: relative;
          margin-left: 0.5%;

          &::before {
            @include font_awesome;

            content: "\f044";
            position: absolute;
            left: 0.5em;
            top: 0.4em;
            color: $color-white;
            font-size: 180%;
          }

          a {
            display: block;
            width: 100%;
            height: 1em;
            font-weight: bold;
            text-align: left;
            padding: 1em 0;
            color: $color-white;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .voice {
      ul {
        li {
          padding: 0.25em 0;

          // お名前
          &:nth-child(1) {
            width: 40%;
          }

          // 評価星
          &:nth-child(2) {
            width: 20%;
          }

          // 日付
          &:nth-child(3) {
            width: 40%;
          }
        }
      }
    }
  }
}

/* ***************************************************************** */

/*  404ページ 20190213 */

/* ***************************************************************** */
.page404 {
  min-height: 360px;

  h2 {
    position: relative;
    font-size: 125%;
    color: #f00;
    font-weight: bold;

    &::before {
      font-family: "Font Awesome 5 Pro";
      white-space: pre-wrap;
      content: "\ea08\00a0";
      font-size: 100%;
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 0.5em;
  }

  .sec01 {
    width: 100%;
    padding: 5% 0;
    box-sizing: border-box;
    font-size: 125%;
  }

  @media screen and (min-width: 1024px) {
    min-height: 768px;

    h2 {
      font-size: 150%;

      &::before {
        font-size: 100%;
      }
    }

    .sec01 {
      padding: 10%;
      font-size: 125%;
    }
  }
}

/* ***************************************************************** */

/*  栄養成分表 20190218 */

/* ***************************************************************** */
.nutrition-wrapper h3 {
  margin-bottom: 0.25em;
}

.nutrition {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  border-top: 2px solid #333;
}

.nutrition .cell01,
.nutrition .cell02,
.nutrition .cell03 {
  width: 50%;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

.nutrition .cell02,
.nutrition .cell03 {
  width: 100%;
}

.nutrition .cell01 .ttl,
.nutrition .cell02 .ttl,
.nutrition .cell03 .ttl,
.nutrition .cell01 .value,
.nutrition .cell02 .value,
.nutrition .cell03 .value {
  padding: 3px;
  box-sizing: border-box;
}

.nutrition .cell01 .ttl {
  width: 50%;
}

.nutrition .cell02 .ttl {
  width: 25%;
}

.nutrition .cell03 .ttl {
  width: 25%;
}

.nutrition .cell01 .value {
  width: 50%;
}

.nutrition .cell02 .value {
  width: 75%;
}

.nutrition .cell03 .value {
  width: 75%;
}

@media screen and (min-width: 768px) {

  .nutrition .cell01,
  .nutrition .cell02 {
    width: 33.33%;
  }

  .nutrition .cell02 .ttl {
    width: 25%;
  }

  .nutrition .cell03 .ttl {
    width: 16.5%;
  }

  .nutrition .cell03 .value {
    width: 83.5%;
  }

  .nutrition div:last-of-type {
    width: 66.66%;
  }

  .nutrition div.cell03:last-of-type {
    width: 100%;
  }
}

/* ***************************************************************** */

/* 商品規格表 */

/* ***************************************************************** */
.item-kikaku {
  width: 100%;
  border: 1px solid #aaa;
  box-sizing: border-box;

  td {
    text-align: left;
    padding: 0.5em;
    border-bottom: 1px solid #aaa;
    display: block;
    box-sizing: border-box;

    &:last-child {
      background-color: $color-white;
    }

    dt {
      color: #f60;
    }

    dd {
      display: block;
      margin: 0 0 0 1em;
    }
  }

  col.item-entry {
    background-color: #feeebb;
    text-align: center;
    width: 8em;
  }

  @media screen and (min-width: 1024px) {
    td {
      width: auto;
      display: table-cell;
    }

    col.item-entry {
      background-color: #feeebb;
      text-align: center;
      width: 8em;
    }
  }
}

/* ***************************************************************** */

/* 定期総額表示 */

/* ***************************************************************** */
// =====
// 定期コースのご注文について（入力内容確認ページ）
// =====
.regAgreement {
  margin: 4% auto;
  padding: 4%;
  border-radius: 3px;
  border: #dfdfce solid 1px;
  box-sizing: border-box;
  text-align: left;

  h4,
  h5 {
    margin-bottom: 0.25em;
    font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
    font-size: 150%;
    color: #ef313a;
  }

  em,
  strong {
    font-weight: bold;
    font-style: normal;
    color: #ef313a;
  }

  table.regularSingle,
  table.regularTotal {
    border-collapse: collapse;
    border: #a7a7a7 3px solid;
  }

  caption {
    padding: 5px;
    background: #ffbf00;
    border: #a7a7a7 3px solid;
    border-bottom: none;
    color: #333;
    font-size: 125%;
    text-align: center;
    caption-side: top;
  }

  table td,
  table th {
    padding: 0.5em;
    box-sizing: border-box;
    background: $color-white;
    border: #a7a7a7 3px solid;
    vertical-align: middle;
  }

  table.regularSingle {
    margin-bottom: 2em;
  }

  colgroup.colHead {
    width: 35%;
  }

  col.colDetail {
    width: 65%;
  }

  li::before {
    content: "※ ";
  }

  @media screen and (min-width: 768px) {
    caption br {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    padding: 3% 5%;
    border: #dfdfce solid 5px;
    font-size: 100%;
    border-radius: 10px;

    caption {
      font-size: 150%;
    }

    table td,
    table th {
      padding: 8px 6px 4px;
      font-size: 150%;
      line-height: 1;
    }

    colgroup.colHead {
      width: 27%;
    }

    col.colDetail {
      width: 73%;
    }
  }
}

// =====
// 定期コースのご注文について（商品ページ）
// =====
.regItemAgreement {
  margin: 1em auto 3em;
  padding: 1.25em;
  background: $color-white;
  border-radius: 3px;
  border: #dfdfce solid 2px;
  box-sizing: border-box;
  text-align: left;

  p {
    font-size: 1.3rem !important;
  }

  em,
  strong {
    font-weight: bold;
    font-style: normal;
    color: #ef313a;
  }

  h5 {
    position: relative;
    width: 13em;
    margin: 0 0 1em 1.5em;
    border-bottom: 1px #f90 dotted;
    font-size: 1.25rem;

    &::before {
      position: absolute;
      width: 2em;
      left: -2em;
      top: 0;
      content: " ";
      height: 100%;
      background: url("/html/template/million01/assets/img/common/bg_regItem_bf.png") left top no-repeat;
      background-size: auto 100%;
    }

    &::after {
      position: absolute;
      width: 2em;
      right: -2em;
      top: 0;
      content: " ";
      height: 100%;
      background: url("/html/template/million01/assets/img/common/bg_regItem_af.png") left top no-repeat;
      background-size: auto 100%;
    }
  }

  ol {
    margin-top: 1em;
    padding: 1em 0 0 1.25em;
    border-top: 1px #f90 dotted;
    width: 100%;
    box-sizing: border-box;

    li {
      list-style: outside decimal;
      padding-bottom: 0.5em;

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    strong {
      padding-bottom: 2px;
      font-weight: bold;
      color: #ef313a;
      background: url("/html/template/million01/assets/img/common/bg_line_wavy.png") bottom repeat-x;
      background-size: 14px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 2em;
    border: #dfdfce solid 5px;
    border-radius: 10px;

    h5 {
      border-bottom: 1px #f90 dotted;
      font-size: 1.5rem;
    }

    strong {
      background-size: 20px;
    }
  }
}
