@charset 'UTF-8';

// =====
// color
// =====

$color-black: #333; // 文字
$color-white: #fff; // 背景
$color-warm: #ff791c; // アクセント橙
$color-dark: #006834; // アクセント緑
$color-dark02: #336600; // アクセント緑
$color-caution: #d01818; // エラー文字
$color-dark-red: #c50000; // 強調文字
$color-million-blue: #1a3456; // コーポレートカラー
$color-link-blue: #2f609a; // リンクカラー


/* ***************************************************************** */
/* @font-face */
/* ***************************************************************** */
@font-face {
	font-family: "YakuHanJP";
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: url("/css/fonts/YakuHanJP-Regular.eot");
  src: url("/css/fonts/YakuHanJP-Regular.woff2") format("woff2"), url("/css/fonts/YakuHanJP-Regular.woff") format("woff");
}

@mixin font_awesome {
	font-family: "Font Awesome 5 Pro";
	speak: none;
	font-style: normal;
	font-weight: 700;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* ***************************************************************** */
/* 汎用クラス */
/* ***************************************************************** */

.f_right {
	float: right;
}
.f_left {
	float: left;
}

// =====
// clearfix
// =====
@mixin cf {
	&:after,
	&:before {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
	zoom: 1;
}

.cf,
.clearfix {
	@include cf;
}

/* ***************************************************************** */
/* mixin */
/* ***************************************************************** */

// =====
// webアイコンのセンタリング
// =====

@mixin icoCenter {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

// =====
// 背景指定（ファイル名、背景横幅サイズ）
// =====

@mixin rankBg($filename, $per) {
	background: url($filename) center bottom no-repeat;
	background-size: $per auto;
}
